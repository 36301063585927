@import "reset";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@400;700&family=Roboto:wght@400;700&display=swap');
@import url("https://file-fontawesome.cdn.bangmod.cloud/fontawesome-pro/css/all.css");

body {
    font-family: 'Roboto', 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    color: #fff;
}

html,
body {
    height: 100%;
}


#root {
    height: 100%;
}

.container {
    position: relative;
    height: 100%;
    padding: 0 16px;
}

.upload-outer {
    width: 100%;
    margin-bottom: 32px;
}

.content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}

.drag-box {
    width: calc(100% - 4px);
    height: 200px;
    margin: 0 auto;
    position: relative;
    border: 2px dashed rgba(#cedd28, 0.5);
    background-color: rgba(#cedd28, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
    cursor: pointer;

    @media (min-width: 1024px) {
        width: 500px;
    }

    &:hover {
        background-color: rgba(#cedd28, 0.1);
        border: 2px dashed rgba(#cedd28, 1);
    }

    input[type="file"] {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}

.drag-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
        margin-bottom: 8px;
        color: #666;
    }

    .remark {
        font-size: 12px;
        color: #999;
    }
}

.draging {
    transform: scale(1.1);
}

#preview {
    text-align: center;

    img {
        max-width: 100%
    }
}

.icon-img {
    width: 30px;
    display: block;
    margin-bottom: 16px;
}

canvas {
    margin-bottom: 32px;
    max-width: 100%;
    max-height: 300px;

    @media (min-width: 1024px) {
        max-width: 500px;
    }
}

button {
    background-color: #cedd28;
    border: none;
    color: #000;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Roboto', 'Noto Sans Thai', sans-serif;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin-bottom: 24px;

    &:hover {
        background-color: #bfcd24;
    }
}